<template>
  <validation-observer ref="ensValidation">
    <b-modal
      v-if="setting"
      :id="this.$attrs.id"
      title="Company Ens Settings"
      size="xl"
      scrollable
    >
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Message type"
            label-for="message_type"
          >
            <validation-provider
              #default="{ errors }"
              name="Message type"
              rules="required"
            >
              <v-select
                id="message_type"
                v-model="setting.message_type"
                label="value"
                :state="errors.length > 0 ? false:null"
                :options="messages"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <h3>Declarant</h3>
        </b-col>
      </b-row>
      <div v-if="setting.declarant">
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Name"
              label-for="declarant_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required|max:70"
              >
                <b-form-input
                  id="declarant_name"
                  v-model="setting.declarant.name"
                  name="declarant_name"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="EORI number"
              label-for="declarant_eori_number"
            >
              <validation-provider
                #default="{ errors }"
                name="EORI number"
                rules="required|alpha_num|max:17"
              >
                <b-form-input
                  id="declarant_eori_number"
                  v-model="setting.declarant.eori_number"
                  name="declarant_eori_number"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h4>Address</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Country ISO code"
              label-for="declarant_country"
            >
              <validation-provider
                #default="{ errors }"
                name="Country ISO code"
                rules="required|alpha|length:2"
              >
                <b-form-input
                  id="declarant_country"
                  v-model="setting.declarant.address.country"
                  name="declarant_country"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="City"
              label-for="declarant_city"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required|max:35"
              >
                <b-form-input
                  id="declarant_city"
                  v-model="setting.declarant.address.city"
                  name="declarant_city"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Street"
              label-for="declarant_street"
            >
              <validation-provider
                #default="{ errors }"
                name="Street"
                rules="required|max:70"
              >
                <b-form-input
                  id="declarant_street"
                  v-model="setting.declarant.address.street"
                  name="declarant_street"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Street number"
              label-for="declarant_street_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Street number"
                rules="required|max:35"
              >
                <b-form-input
                  id="declarant_street_number"
                  v-model="setting.declarant.address.street_number"
                  name="declarant_street_number"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Postcode"
              label-for="postcode"
            >
              <validation-provider
                #default="{ errors }"
                name="Postcode"
                rules="required|max:17"
              >
                <b-form-input
                  id="declarant_postcode"
                  v-model="setting.declarant.address.postcode"
                  name="declarant_postcode"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h4>Communication</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Type"
              label-for="declarant_type"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  id="declarant_type"
                  v-model="setting.declarant.communication.type"
                  label="label"
                  :reduce="option => option.value"
                  :state="errors.length > 0 ? false:null"
                  :options="[{label: 'Email', value: 'EM'}, {label: 'Phone', value: 'TE'}]"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Value"
              label-for="declarant_value"
            >
              <validation-provider
                #default="{ errors }"
                name="Value"
                :rules="setting.declarant.communication.type === 'EM' ? 'required|email|max:512' : 'required|max:512'"
              >
                <b-form-input
                  id="declarant_value"
                  v-model="setting.declarant.communication.value"
                  name="declarant_value"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr>
      <b-row>
        <b-col class="d-flex">
          <h3>Representative</h3>
          <b-button
            variant="link"
            size="sm"
            class="ml-2 p-0"
            @click="handleRepresentative"
          >
            <span v-if="setting.representative === null">+ Add representative details</span>
            <span v-else>- Remove representative</span>
          </b-button>
        </b-col>
      </b-row>
      <div v-if="setting.representative !== null">
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Name"
              label-for="representative_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required|max:70"
              >
                <b-form-input
                  id="representative_name"
                  v-model="setting.representative.name"
                  name="representative_name"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="EORI number"
              label-for="representative_eori_number"
            >
              <validation-provider
                #default="{ errors }"
                name="EORI number"
                rules="required|alpha_num|max:17"
              >
                <b-form-input
                  id="representative_eori_number"
                  v-model="setting.representative.eori_number"
                  name="representative_eori_number"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Status"
              label-for="representative_status"
            >
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  id="representative_status"
                  v-model="setting.representative.status"
                  label="label"
                  :reduce="option => option.value"
                  :state="errors.length > 0 ? false:null"
                  :options="[{label: 'Direct representative', value: 2}, {label: 'Indirect representative', value: 3}]"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h4>Address</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Country ISO code"
              label-for="representative_country"
            >
              <validation-provider
                #default="{ errors }"
                name="Country ISO code"
                rules="required|alpha|length:2"
              >
                <b-form-input
                  id="representative_country"
                  v-model="setting.representative.address.country"
                  name="representative_country"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="City"
              label-for="city"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required|max:35"
              >
                <b-form-input
                  id="city"
                  v-model="setting.representative.address.city"
                  name="city"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Street"
              label-for="representative_street"
            >
              <validation-provider
                #default="{ errors }"
                name="Street"
                rules="required|max:70"
              >
                <b-form-input
                  id="representative_street"
                  v-model="setting.representative.address.street"
                  name="representative_street"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Street number"
              label-for="representative_street_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Street number"
                rules="required|max:35"
              >
                <b-form-input
                  id="representative_street_number"
                  v-model="setting.representative.address.street_number"
                  name="representative_street_number"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Postcode"
              label-for="representative_postcode"
            >
              <validation-provider
                #default="{ errors }"
                name="Postcode"
                rules="required|max:17"
              >
                <b-form-input
                  id="representative_postcode"
                  v-model="setting.representative.address.postcode"
                  name="representative_postcode"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h4>Communication</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Type"
              label-for="representative_type"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  id="representative_type"
                  v-model="setting.representative.communication.type"
                  label="label"
                  :reduce="option => option.value"
                  :state="errors.length > 0 ? false:null"
                  :options="[{label: 'Email', value: 'EM'}, {label: 'Phone', value: 'TE'}]"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Value"
              label-for="representative_value"
            >
              <validation-provider
                #default="{ errors }"
                name="Value"
                :rules="setting.representative.communication.type === 'EM' ? 'required|email|max:512' : 'required|max:512'"
              >
                <b-form-input
                  id="representative_value"
                  v-model="setting.representative.communication.value"
                  name="representative_value"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <template #modal-footer="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="text-right">
              <b-button
                :disabled="formBusy"
                variant="secondary"
                class="mr-2"
                @click="close"
              >
                Close
              </b-button>
              <b-button
                :disabled="formBusy"
                type="button"
                variant="primary"
                @click="submitForm"
              >
                <b-spinner
                  v-if="formBusy"
                  small
                />
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BContainer,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BSpinner,
    BContainer,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: ['eventHub', 'setting', 'messages', 'companyId'],
  data() {
    return {
      formBusy: false,
    }
  },
  methods: {
    submitForm() {
      this.$refs.ensValidation.validate().then(success => {
        if (!success) {
          return
        }

        this.formBusy = true

        if (this.setting.id) {
          this.$http.put(`/v1/companies/${this.companyId}/ensSettings/${this.setting.id}`, this.setting)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ens Setting edited',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('ens-settings-modal')
              this.eventHub.$emit('refreshEnsSettings')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        } else {
          this.$http.post(`/v1/companies/${this.companyId}/ensSettings`, this.setting)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ens setting added',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('ens-settings-modal')
              this.eventHub.$emit('refreshEnsSettings')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        }
      })
    },
    handleRepresentative() {
      if (this.setting.representative === null) {
        this.setting.representative = {
          name: '',
          eori_number: '',
          status: '',
          address: {
            street: '',
            street_number: '',
            postal_code: '',
            city: '',
            country: '',
          },
          communication: {
            type: '',
            value: '',
          },
        }
      } else {
        this.setting.representative = null
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
