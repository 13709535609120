<template>
  <b-tab>
    <template #title>
      <feather-icon
        icon="PaperclipIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Ens settings</span>
    </template>

    <b-card>
      <b-row v-if="messageTypesOptions.length">
        <b-col class="text-right">
          <b-button
            v-b-modal.ens-settings-modal
            variant="outline-primary"
            class="m-1"
            :disabled="loading"
            @click="setNewSetting"
          >
            Add setting
          </b-button>
        </b-col>
      </b-row>
      <ens-settings-modal
        id="ens-settings-modal"
        :setting="selectedSetting"
        :messages="messageTypesOptions"
        :event-hub="eventHub"
        :company-id="companyId"
      />
      <b-row>
        <b-col>
          <div class="overflow-x-scroll">
            <b-table
              id="carriers-table"
              :items="ensSettings"
              responsive
              :fields="fields"
              :busy="loading"
              stacked="sm"
              class="mb-0"
              show-empty
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>

              <template
                #cell(declarant)="data"
                class="px-1"
              >
                {{ data.item.declarant.name }}, {{ data.item.declarant.eori_number }}, {{ data.item.declarant.address.country }}
              </template>

              <template
                #cell(representative)="data"
                class="px-1"
              >
                <span v-if="data.item.representative">
                  {{ data.item.representative.name }}, {{ data.item.representative.eori_number }}, {{ data.item.representative.address.country }}
                </span>
                <span v-else>-</span>
              </template>

              <template
                #cell(created_at)="data"
                class="px-1"
              >
                {{ data.item.created_at | formatDate }}
              </template>

              <template
                #cell(updated_at)="data"
                class="px-1"
              >
                {{ data.item.updated_at | formatDate }}
              </template>

              <template
                #cell(actions)="data"
                class="px-1"
              >
                <b-button-group>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="editSetting(data.item.id)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline"
                    />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="deleteSetting(data.item.id)"
                  >
                    <feather-icon
                      v-if="deletingId !== data.item.id"
                      icon="TrashIcon"
                      class="d-inline"
                    />
                    <b-spinner
                      v-else
                      small
                    />
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-tab>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BSpinner,
  BCard,
  BButtonGroup, BTab,
} from 'bootstrap-vue'
import Vue from 'vue'
import EnsSettingsModal from './EnsSettingsModal.vue'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BTab,
    BButtonGroup,
    BSpinner,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    EnsSettingsModal,
  },
  props: ['companyId'],
  data() {
    return {
      messageTypesOptions: ['F22', 'F26'],
      ensSettings: [],
      selectedSetting: null,
      loading: false,
      eventHub: new Vue(),
      fields: [
        { key: 'message_type', label: 'Message' },
        { key: 'declarant', label: 'Declarant' },
        { key: 'representative', label: 'Representative' },
        { key: 'created_at', label: 'Created at' },
        { key: 'updated_at', label: 'Updated_at' },
        { key: 'actions', label: 'Actions' },
      ],
      deletingId: null,
    }
  },
  created() {
    this.getEnsSettings()
    this.eventHub.$on('refreshEnsSettings', () => {
      this.getEnsSettings()
    })
  },
  methods: {
    getEnsSettings() {
      this.loading = true

      this.$http.get(`/v1/companies/${this.companyId}/ensSettings`)
        .then(response => {
          this.ensSettings = response.data.data
          this.removeImplementedMessages()
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
    removeImplementedMessages() {
      const settingsExists = this.ensSettings.map(setting => setting.message_type)
      this.messageTypesOptions = this.messageTypesOptions.filter(message => !settingsExists.includes(message))
      if (this.ensSettings.length === 0 && this.messageTypesOptions.length === 0) {
        this.messageTypesOptions = ['F22']
      }
    },
    editSetting(id) {
      this.selectedSetting = this.ensSettings.find(setting => setting.id === id)
      this.$bvModal.show('ens-settings-modal')
    },
    deleteSetting(id) {
      this.deletingId = id
      this.$http.delete(`/v1/companies/${this.companyId}/ensSettings/${id}`)
        .then(() => {
          const index = this.ensSettings.find(setting => setting.id === id)
          this.ensSettings.splice(index, 1)
          this.removeImplementedMessages()
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.deletingId = null
        })
    },
    setNewSetting() {
      this.selectedSetting = {
        id: null,
        message_type: '',
        declarant: {
          name: '',
          eori_number: '',
          address: {
            street: '',
            street_number: '',
            postal_code: '',
            city: '',
            country: '',
          },
          communication: {
            type: '',
            value: '',
          },
        },
        representative: null,
      }
    },
  },
}
</script>

<style lang="scss">
</style>
