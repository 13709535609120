<template>
  <validation-observer ref="contractValidation">
    <b-modal
      v-if="contract && contract.configuration"
      :id="this.$attrs.id"
      title="Company contract"
      size="l"
      scrollable
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Valid from"
            label-for="valid-from"
          >
            <validation-provider
              #default="{ errors }"
              name="Valid from"
              rules="required"
            >
              <b-form-datepicker
                id="valid-from"
                v-model="contract.valid_from"
                class="mb-1"
                :disabled="!$permissions().hasPermission('edit contracts')"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Valid to"
            label-for="valid-to"
          >
            <b-form-datepicker
              id="valid-to"
              v-model="contract.valid_to"
              class="mb-1"
              :disabled="!$permissions().hasPermission('edit contracts')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="GDPR Days"
            label-for="gdpr-days"
          >
            <validation-provider
              #default="{ errors }"
              name="GDPR Days"
              rules="required|numeric"
            >
              <b-form-input
                id="gdprDays"
                v-model="contract.configuration.gdprDays"
                :disabled="!$permissions().hasPermission('edit contracts')"
                name="gdprDays"
                :state="errors.length > 0 ? false:null"
                autocomplete="off"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Require regular user password changes"
            label-for="regular-pass"
          >
            <b-form-checkbox
              id="regular-pass"
              v-model="contract.configuration.agingPassword"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" v-if="contract.configuration.agingPassword">
          <b-form-group
            label="Days until password change"
            label-for="password-change"
          >
            <validation-provider
              #default="{ errors }"
              name="Days until password change"
              rules="numeric"
            >
              <b-form-input
                id="password-change"
                v-model="contract.configuration.passwordChangeDays"
                :disabled="!$permissions().hasPermission('edit contracts')"
                name="password-change"
                :state="errors.length > 0 ? false : null"
                autocomplete="off"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Tags"
            label-for="tags"
          >
            <v-select
              id="tags"
              label="value"
              v-model="contract.tags"
              multiple
              :options="tagsList"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Cainiao App key"
            label-for="app-key"
          >
            <b-form-input
              id="app-key"
              v-model="contract.configuration.CAINIAO_APP_KEY"
              :disabled="!$permissions().hasPermission('edit contracts')"
              name="app-key"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Cainiao Sign key"
            label-for="sign-key"
          >
            <b-form-input
              id="sign-key"
              v-model="contract.configuration.CAINIAO_SIGN_KEY"
              :disabled="!$permissions().hasPermission('edit contracts')"
              name="sign-key"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Cainiao Provider ID"
            label-for="provider-id"
          >
            <b-form-input
              id="provider-id"
              v-model="contract.configuration.CAINIAO_PROVIDER_ID"
              :disabled="!$permissions().hasPermission('edit contracts')"
              name="provider-id"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Cainiao Encrypt Key"
            label-for="encrypt-key"
          >
            <b-form-input
              id="encrypt-key"
              v-model="contract.configuration.CAINIAO_ENCRYPT_KEY"
              :disabled="!$permissions().hasPermission('edit contracts')"
              name="encrypt-key"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="text-right">
              <b-button
                :disabled="formBusy"
                variant="secondary"
                class="mr-2"
                @click="close"
              >
                Close
              </b-button>
              <b-button
                :disabled="formBusy"
                type="button"
                variant="primary"
                @click="submitForm"
              >
                <b-spinner
                  v-if="formBusy"
                  small
                />
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BButton,
  BFormCheckbox,
  BModal,
  BContainer,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BSpinner,
    BContainer,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
  },
  props: ['eventHub', 'contract', 'companyId', 'tagsList'],
  data() {
    return {
      formBusy: false,
    }
  },
  methods: {
    submitForm() {
      this.$refs.contractValidation.validate().then(success => {
        if (!success) {
          return
        }

        this.formBusy = true

        if (this.contract.id) {
          this.$http.put(`/v1/companies/${this.companyId}/contracts/${this.contract.id}`, this.contract)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contract edited',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('company-contract-modal')
              this.eventHub.$emit('refreshContracts')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        } else {
          this.$http.post(`/v1/companies/${this.companyId}/contracts`, this.contract)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contract added',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('company-contract-modal')
              this.eventHub.$emit('refreshContracts')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
