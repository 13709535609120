var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"ensValidation"},[(_vm.setting)?_c('b-modal',{attrs:{"id":this.$attrs.id,"title":"Company Ens Settings","size":"xl","scrollable":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.formBusy,"variant":"secondary"},on:{"click":close}},[_vm._v(" Close ")]),_c('b-button',{attrs:{"disabled":_vm.formBusy,"type":"button","variant":"primary"},on:{"click":_vm.submitForm}},[(_vm.formBusy)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Submit")])],1)],1)],1)],1)]}}],null,false,589492523)},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Message type","label-for":"message_type"}},[_c('validation-provider',{attrs:{"name":"Message type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"message_type","label":"value","state":errors.length > 0 ? false:null,"options":_vm.messages},model:{value:(_vm.setting.message_type),callback:function ($$v) {_vm.$set(_vm.setting, "message_type", $$v)},expression:"setting.message_type"}})]}}],null,false,2438032416)})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',[_c('h3',[_vm._v("Declarant")])])],1),(_vm.setting.declarant)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"declarant_name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_name","name":"declarant_name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.name),callback:function ($$v) {_vm.$set(_vm.setting.declarant, "name", $$v)},expression:"setting.declarant.name"}})]}}],null,false,2742110005)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"EORI number","label-for":"declarant_eori_number"}},[_c('validation-provider',{attrs:{"name":"EORI number","rules":"required|alpha_num|max:17"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_eori_number","name":"declarant_eori_number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.eori_number),callback:function ($$v) {_vm.$set(_vm.setting.declarant, "eori_number", $$v)},expression:"setting.declarant.eori_number"}})]}}],null,false,1922802975)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Address")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Country ISO code","label-for":"declarant_country"}},[_c('validation-provider',{attrs:{"name":"Country ISO code","rules":"required|alpha|length:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_country","name":"declarant_country","state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.address.country),callback:function ($$v) {_vm.$set(_vm.setting.declarant.address, "country", $$v)},expression:"setting.declarant.address.country"}})]}}],null,false,2614035650)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"declarant_city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_city","name":"declarant_city","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.address.city),callback:function ($$v) {_vm.$set(_vm.setting.declarant.address, "city", $$v)},expression:"setting.declarant.address.city"}})]}}],null,false,2489461453)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Street","label-for":"declarant_street"}},[_c('validation-provider',{attrs:{"name":"Street","rules":"required|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_street","name":"declarant_street","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.address.street),callback:function ($$v) {_vm.$set(_vm.setting.declarant.address, "street", $$v)},expression:"setting.declarant.address.street"}})]}}],null,false,868070379)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Street number","label-for":"declarant_street_number"}},[_c('validation-provider',{attrs:{"name":"Street number","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_street_number","name":"declarant_street_number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.address.street_number),callback:function ($$v) {_vm.$set(_vm.setting.declarant.address, "street_number", $$v)},expression:"setting.declarant.address.street_number"}})]}}],null,false,27589271)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Postcode","label-for":"postcode"}},[_c('validation-provider',{attrs:{"name":"Postcode","rules":"required|max:17"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_postcode","name":"declarant_postcode","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.address.postcode),callback:function ($$v) {_vm.$set(_vm.setting.declarant.address, "postcode", $$v)},expression:"setting.declarant.address.postcode"}})]}}],null,false,3331603647)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Communication")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"declarant_type"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"declarant_type","label":"label","reduce":function (option) { return option.value; },"state":errors.length > 0 ? false:null,"options":[{label: 'Email', value: 'EM'}, {label: 'Phone', value: 'TE'}]},model:{value:(_vm.setting.declarant.communication.type),callback:function ($$v) {_vm.$set(_vm.setting.declarant.communication, "type", $$v)},expression:"setting.declarant.communication.type"}})]}}],null,false,2759138521)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Value","label-for":"declarant_value"}},[_c('validation-provider',{attrs:{"name":"Value","rules":_vm.setting.declarant.communication.type === 'EM' ? 'required|email|max:512' : 'required|max:512'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"declarant_value","name":"declarant_value","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.declarant.communication.value),callback:function ($$v) {_vm.$set(_vm.setting.declarant.communication, "value", $$v)},expression:"setting.declarant.communication.value"}})]}}],null,false,832742199)})],1)],1)],1)],1):_vm._e(),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"d-flex"},[_c('h3',[_vm._v("Representative")]),_c('b-button',{staticClass:"ml-2 p-0",attrs:{"variant":"link","size":"sm"},on:{"click":_vm.handleRepresentative}},[(_vm.setting.representative === null)?_c('span',[_vm._v("+ Add representative details")]):_c('span',[_vm._v("- Remove representative")])])],1)],1),(_vm.setting.representative !== null)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"representative_name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"representative_name","name":"representative_name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.representative.name),callback:function ($$v) {_vm.$set(_vm.setting.representative, "name", $$v)},expression:"setting.representative.name"}})]}}],null,false,3785500768)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"EORI number","label-for":"representative_eori_number"}},[_c('validation-provider',{attrs:{"name":"EORI number","rules":"required|alpha_num|max:17"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"representative_eori_number","name":"representative_eori_number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.representative.eori_number),callback:function ($$v) {_vm.$set(_vm.setting.representative, "eori_number", $$v)},expression:"setting.representative.eori_number"}})]}}],null,false,268563530)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"representative_status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"representative_status","label":"label","reduce":function (option) { return option.value; },"state":errors.length > 0 ? false:null,"options":[{label: 'Direct representative', value: 2}, {label: 'Indirect representative', value: 3}]},model:{value:(_vm.setting.representative.status),callback:function ($$v) {_vm.$set(_vm.setting.representative, "status", $$v)},expression:"setting.representative.status"}})]}}],null,false,1000221048)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Address")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Country ISO code","label-for":"representative_country"}},[_c('validation-provider',{attrs:{"name":"Country ISO code","rules":"required|alpha|length:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"representative_country","name":"representative_country","state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.setting.representative.address.country),callback:function ($$v) {_vm.$set(_vm.setting.representative.address, "country", $$v)},expression:"setting.representative.address.country"}})]}}],null,false,150480695)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","name":"city","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.representative.address.city),callback:function ($$v) {_vm.$set(_vm.setting.representative.address, "city", $$v)},expression:"setting.representative.address.city"}})]}}],null,false,2271022936)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Street","label-for":"representative_street"}},[_c('validation-provider',{attrs:{"name":"Street","rules":"required|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"representative_street","name":"representative_street","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.representative.address.street),callback:function ($$v) {_vm.$set(_vm.setting.representative.address, "street", $$v)},expression:"setting.representative.address.street"}})]}}],null,false,419633150)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Street number","label-for":"representative_street_number"}},[_c('validation-provider',{attrs:{"name":"Street number","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"representative_street_number","name":"representative_street_number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.representative.address.street_number),callback:function ($$v) {_vm.$set(_vm.setting.representative.address, "street_number", $$v)},expression:"setting.representative.address.street_number"}})]}}],null,false,808319554)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Postcode","label-for":"representative_postcode"}},[_c('validation-provider',{attrs:{"name":"Postcode","rules":"required|max:17"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"representative_postcode","name":"representative_postcode","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.representative.address.postcode),callback:function ($$v) {_vm.$set(_vm.setting.representative.address, "postcode", $$v)},expression:"setting.representative.address.postcode"}})]}}],null,false,1517157290)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Communication")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"representative_type"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"representative_type","label":"label","reduce":function (option) { return option.value; },"state":errors.length > 0 ? false:null,"options":[{label: 'Email', value: 'EM'}, {label: 'Phone', value: 'TE'}]},model:{value:(_vm.setting.representative.communication.type),callback:function ($$v) {_vm.$set(_vm.setting.representative.communication, "type", $$v)},expression:"setting.representative.communication.type"}})]}}],null,false,179014521)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Value","label-for":"representative_value"}},[_c('validation-provider',{attrs:{"name":"Value","rules":_vm.setting.representative.communication.type === 'EM' ? 'required|email|max:512' : 'required|max:512'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"representative_value","name":"representative_value","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.setting.representative.communication.value),callback:function ($$v) {_vm.$set(_vm.setting.representative.communication, "value", $$v)},expression:"setting.representative.communication.value"}})]}}],null,false,946750882)})],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }