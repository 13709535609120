<template>
  <validation-observer ref="webhookValidation">
    <b-modal
      :id="this.$attrs.id"
      title="Company Webhook"
      size="lg"
      scrollable
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required|max:255"
            >
              <b-form-input
                id="name"
                v-model="webhook.name"
                name="name"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Url"
            label-for="url"
          >
            <validation-provider
              #default="{ errors }"
              name="Url"
              rules="required|url"
            >
              <b-form-input
                id="url"
                v-model="webhook.url"
                name="url"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Status"
            label-for="status"
          >
            <b-form-checkbox
              id="status"
              v-model="webhook.status"
              switch
              value="active"
              unchecked-value="inactive"
            >
              Active
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Headers">
            <b-button @click="addHeader" variant="primary" size="sm">
              Add Header
            </b-button>
          </b-form-group>

          <b-row v-for="(header, index) in webhook.headers" :key="index">
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Header Type"
                rules="required|header_key|max:255"
              >
                <b-form-input
                  v-model="header.key"
                  placeholder="Header Type"
                  class="mb-2 headerType"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Header Value"
                rules="required|max:1024"
              >
                <b-form-input
                  v-model="header.value"
                  placeholder="Header Value"
                  class="mb-2 headerValue"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="outline-danger"
                size="sm"
                class="headerRemove"
                @click="removeHeader(index)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(group, index1) in hooks"
          :key="index1"
        >
          <b-form-group
            :label="capitalizeFirstChar(group.value.replace('_', ' '))"
            label-for="hooks"
          >
            <b-form-checkbox
              v-for="(setting, index) in group.text"
              :id="group.value + '/' + setting.value"
              :key="index"
              v-model="webhook.callbacks[group.value + '/' + setting.value]"
              :checked="true"
              switch
            >
              {{ setting.text }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="text-left">
              <b-button
                :disabled="buttonsBusy"
                type="button"
                variant="primary"
                @click="testWebhook"
              >
                <b-spinner
                  v-if="testBusy"
                  small
                />
                <span v-else>Test webhook</span>
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button
                :disabled="buttonsBusy"
                variant="secondary"
                class="mr-2"
                @click="close"
              >
                Close
              </b-button>
              <b-button
                :disabled="buttonsBusy"
                type="button"
                variant="primary"
                @click="submitForm"
              >
                <b-spinner
                  v-if="formBusy"
                  small
                />
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BContainer,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

export default {
  components: {
    BFormCheckbox,
    BSpinner,
    BContainer,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    eventHub: {
      default: () => new Vue(),
    },
    webhook: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      formBusy: false,
      testBusy: false,
      hooks: this.$classifiers().getOptions('webhook_callbacks', false),
    }
  },
  computed: {
    buttonsBusy() {
      return this.formBusy || this.testBusy
    },
  },
  methods: {
    capitalizeFirstChar(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    addHeader() {
      this.webhook.headers.push({ key: '', value: '' })
    },
    removeHeader(index) {
      this.webhook.headers.splice(index, 1)
    },
    testWebhook() {
      this.$refs.webhookValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.testBusy = true

        const headers = this.webhook.headers.reduce((acc, header) => {
          if (header.key && header.value) {
            acc[header.key] = header.value
          }
          return acc
        }, {})
        this.$http.post(`/v1/companies/${this.companyId}/webhooks/test`, this.webhook, { headers })
          .then(response => {
            const { status, data } = response
            const parsedHeaders = response.headers
            const formattedHeaders = Object.entries(parsedHeaders)
              .map(([key, value]) => `${key}: ${value}`)
              .join('<br>')
            const formattedData = JSON.stringify(data, null, 2)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Test Webhook sent successfully',
                text: `
                  <strong>Response Status:</strong> ${status}<br>
                  <strong>Response Content:</strong><br>
                  <pre style="white-space: pre-wrap; word-break: break-word;">${formattedData}</pre>
                  <br><strong>Headers:</strong><br>
                  ${formattedHeaders}`,
                icon: 'CheckIcon',
                variant: 'success',
                html: true,
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Test Webhook failed - ${error.response.status}`,
                text: error.response ? error.response.data.message : 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.testBusy = false
          })
      })
    },
    submitForm() {
      this.$refs.webhookValidation.validate().then(success => {
        if (!success) {
          return
        }

        this.formBusy = true

        if (this.webhook.id) {
          const params = { ...this.webhook }
          params.callbacks = []
          Object.keys(this.webhook.callbacks).forEach(key => {
            if (this.webhook.callbacks[key] === true) {
              params.callbacks.push(key)
            }
          })
          this.$http.put(`/v1/companies/${this.companyId}/webhooks/${this.webhook.id}`, params)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Webhook edited',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('webhook-modal')
              this.eventHub.$emit('refreshWebhooks')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        } else {
          this.$http.post(`/v1/companies/${this.companyId}/webhooks`, this.webhook)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Webhook added',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('webhook-modal')
              this.eventHub.$emit('refreshWebhooks')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
