<template>
  <!-- Tab: Country settings -->
  <b-tab
    v-if="canSee()"
    @click="fetchData()"
  >
    <template #title>
      <feather-icon
        icon="PackageIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">{{ countryCode.toUpperCase() }} customs</span>
    </template>
    <div v-if="dataLoaded">
      <div v-if="countryCode === 'sk'">
        <validation-observer ref="customsValidation">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Customs username"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customs username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="formData.sk.username"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customs password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customs password"
                >
                  <b-form-input
                    id="password"
                    v-model="formData.sk.password"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customs senderId"
                label-for="senderId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customs senderId"
                  rules="required"
                >
                  <b-form-input
                    id="senderId"
                    v-model="formData.sk.senderId"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customs receiverId"
                label-for="receiverId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customs receiverId"
                  rules="required"
                >
                  <b-form-input
                    id="receiverId"
                    v-model="formData.sk.receiverId"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customs subjectId"
                label-for="subjectId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customs subjectId"
                  rules="required"
                >
                  <b-form-input
                    id="subjectId"
                    v-model="formData.sk.subjectId"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
      <div v-if="countryCode === 'lv'">
      <validation-observer ref="customsValidation">
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Technical Username"
              label-for="tech-username"
            >
              <validation-provider
                #default="{ errors }"
                name="Technical Username"
                rules="required"
              >
                <b-form-input
                  id="tech-username"
                  v-model="formData.lv.techUser"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Technical Password"
              label-for="tech-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Technical Password"
                rules="required"
              >
                <b-form-input
                  id="tech-password"
                  v-model="formData.lv.techPassword"
                  :type="'password'"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="System Code"
              label-for="system-code"
            >
              <validation-provider
                #default="{ errors }"
                name="System Code"
                rules="required"
              >
                <b-form-input
                  id="system-code"
                  v-model="formData.lv.systemCode"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="TIN"
              label-for="tin"
            >
              <validation-provider
                #default="{ errors }"
                name="TIN"
                rules="required"
              >
                <b-form-input
                  id="tin"
                  v-model="formData.lv.tin"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    </div>
    <div v-else>
      <b-spinner
        v-if="!dataLoaded"
        variant="secondary"
        label="Loading"
      />
    </div>

    <!-- Submit button -->
    <b-row v-if="dataLoaded">
      <b-col>
        <b-button
          type="button"
          variant="primary"
          id="customs-settings-submit"
          @click="submitForm"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </b-tab>
</template>
<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BTab,
  BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSpinner,
    BButton,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: ['country', 'companyId'],
  data() {
    return {
      supportedCountries: [
        'lv',
        'sk',
      ],
      countryCode: '',
      dataLoaded: false,
      formData: {
        lv: {
          edsUser: '',
          edsPassword: '',
          systemCode: '',
          techUser: '',
          techPassword: '',
          tin: '',
        },
        sk: {
          username: '',
          password: '',
          subjectId: '',
          senderId: '',
          receiverId: '',
        },
      },
    }
  },
  created() {
    this.countryCode = this.country.toLowerCase()
  },
  watch: {
    country(newValue) {
      this.countryCode = newValue.toLowerCase()
      if (this.supportedCountries.includes(this.countryCode)) {
        this.dataLoaded = false
        this.fetchData()
      }
    },
  },
  methods: {
    canSee() {
      if (
        this.countryCode === 'sk'
        && !this.$permissions().hasRole('Feeport Admin')
        && !this.$permissions().hasRole('Admin')
      ) {
        return false
      }
      return this.supportedCountries.includes(this.countryCode)
    },
    submitForm() {
      this.$refs.customsValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.$http.post(`/v1/companies/${this.companyId}/customs/${this.countryCode}`, this.formData[this.countryCode])
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
          .finally(() => {
            this.formData.sk.password = ''
          })
      })
    },
    fetchData() {
      if (!this.dataLoaded) {
        this.$http.get(`/v1/companies/${this.companyId}/customs/${this.countryCode}`)
          .then(response => {
            switch (this.countryCode) {
              case 'lv':
                this.$data.formData.lv = response.data
                break
              case 'sk':
                this.$data.formData.sk = response.data
                break
              default:
                break
            }
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
          .finally(() => {
            this.dataLoaded = true
          })
      }
    },
  },
}
</script>
