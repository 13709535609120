<template>
  <!-- Tab: Logo settings -->
  <b-tab>
    <template #title>
      <feather-icon
        icon="ImageIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Logo</span>
    </template>
    <div>
      <validation-observer ref="customsValidation">
        <b-row>
          <b-col
            lg="6"
            md="6"
          >
            <p>We recommend to use logo up to 300x300 pixels and 2Mb</p>
            <b-form-file
              v-if="!upload.isUploading"
              id="attachment-upload-btn"
              v-model="uploadFile"
              placeholder="Select a file"
              drop-placeholder="Drop file here..."
              class="b-custom-control-xl"
              @input="formUploadFile()"
            />
            <b-progress
              v-if="upload.isUploading"
              ref="fileProgress"
              :value="upload.uploadProgress"
              max="100"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
          >
            <img
              v-if="logoImage"
              :src="logoImage"
              width="300px"
              alt="company logo"
            >
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </b-tab>
</template>
<script>
import {
  BCol,
  BRow,
  BTab,
  BProgress,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormFile,
    BProgress,
    BTab,
    BRow,
    BCol,
    ValidationObserver,
  },
  props: ['logo', 'companyId'],
  data() {
    return {
      logoImage: null,
      uploadFile: null,
      upload: {
        isUploading: false,
        uploadProgress: 0,
      },
      fileBaseUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.companyId}/logo`,
    }
  },
  watch: {
    logo(newValue) {
      this.logoImage = newValue
    },
  },
  methods: {
    async formUploadFile() {
      this.upload.isUploading = true
      this.upload.uploadProgress = 0

      try {
        const formData = new FormData()
        formData.append('file', this.uploadFile, this.uploadFile.name)

        const response = await this.$http.post(this.fileBaseUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            this.upload.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          },
        })

        this.upload.isUploading = false
        if (response.data.documentAttachments && response.data.documentAttachments.length > 0) {
          this.input.document_attachments.push(...response.data.documentAttachments)
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File successfully uploaded',
            icon: 'EditIcon',
            variant: 'success',
          },
        })

        const reader = new FileReader()
        this.logoImage = URL.createObjectURL(this.uploadFile)
        reader.readAsDataURL(this.uploadFile)
      } catch (error) {
        this.upload.isUploading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to upload file',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
