<template>
  <b-tab
    id="webhooks-tab"
    @click="loadData"
  >
    <WebhookModal
      v-if="webhooks.length > 0 && $permissions().hasPermission('create company webhook')"
      id="webhook-modal"
      :webhook="selectedWebhook"
      :company-id="companyId"
      :event-hub="eventHub"
    />
    <template #title>
      <feather-icon
        icon="LinkIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Webhooks</span>
    </template>

    <b-card>
      <b-row v-if="$permissions().hasPermission('create company webhook')">
        <b-col class="text-right">
          <b-button
            v-b-modal.webhook-modal
            variant="outline-primary"
            class="m-1"
            :disabled="loading"
            @click="createWebhook"
          >
            Add webhook
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="overflow-x-scroll">
            <b-table
              id="carriers-table"
              :items="webhooks"
              responsive
              :fields="fields"
              :busy="loading"
              stacked="sm"
              class="mb-0"
              show-empty
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>

              <template
                #cell(created_at)="data"
                class="px-1"
              >
                {{ data.item.created_at | formatDate }}
              </template>

              <template
                #cell(updated_at)="data"
                class="px-1"
              >
                {{ data.item.updated_at | formatDate }}
              </template>

              <template
                #cell(actions)="data"
                class="px-1"
              >
                <b-button-group>
                  <b-button
                    v-if="$permissions().hasPermission('edit company webhook')"
                    variant="primary"
                    size="sm"
                    @click="editWebhook(data.item.id)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline"
                    />
                  </b-button>
                  <b-button
                    v-if="$permissions().hasPermission('delete company webhook')"
                    variant="outline-danger"
                    size="sm"
                    @click="deleteWebhook(data.item.id)"
                  >
                    <feather-icon
                      v-if="deletingId !== data.item.id"
                      icon="TrashIcon"
                      class="d-inline"
                    />
                    <b-spinner
                      v-else
                      small
                    />
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-tab>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BSpinner,
  BCard,
  BButtonGroup, BTab,
} from 'bootstrap-vue'
import Vue from 'vue'
import handleError from '@/views/components/errorHandler'
import WebhookModal from '@/views/company/components/webhook/WebhookModal.vue'

export default {
  components: {
    WebhookModal,
    BTab,
    BButtonGroup,
    BSpinner,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
  },
  props: ['companyId'],
  data() {
    return {
      webhooks: [],
      selectedWebhook: null,
      loading: false,
      eventHub: new Vue(),
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'status', label: 'Status' },
        { key: 'url', label: 'Url' },
        { key: 'created_at', label: 'Created at' },
        { key: 'actions', label: 'Actions' },
      ],
      deletingId: null,
    }
  },
  created() {
    this.eventHub.$on('refreshWebhooks', () => {
      this.getWebhooks()
    })
  },
  methods: {
    loadData() {
      this.createWebhook()
      this.getWebhooks()
    },
    getWebhooks() {
      this.loading = true

      this.$http.get(`/v1/companies/${this.companyId}/webhooks`)
        .then(response => {
          this.webhooks = response.data.data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
    editWebhook(id) {
      this.selectedWebhook = this.webhooks.find(webhook => webhook.id === id)
      this.$bvModal.show('webhook-modal')
    },
    deleteWebhook(id) {
      this.deletingId = id
      this.$http.delete(`/v1/companies/${this.companyId}/webhooks/${id}`)
        .then(() => {
          const index = this.webhooks.findIndex(webhook => webhook.id === id)
          if (index !== -1) {
            this.webhooks.splice(index, 1)
          }
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.deletingId = null
        })
    },
    createWebhook() {
      this.selectedWebhook = {
        name: '',
        url: '',
        status: 'inactive',
        callbacks: {},
        headers: [],
      }
    },
  },
}
</script>

<style lang="scss">
</style>
